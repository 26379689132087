@font-face {
  font-family: 'ubuntu_mono';
  src: url('assets/UbuntuMono-Regular.ttf');
}

:root {
  --color-primary: rgba(23, 33, 32, 1);
  --color-secondary: rgba(35, 44, 43, 1);

  --border-radius: 12px;
  --border-radius-large: 24px;
}

body {
  margin: 0;
  font-family: 'ubuntu_mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-primary);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glass {
  background: rgba(35, 44, 43, 0.65);
  box-shadow: 0 8px 32px 0 rgba(23, 33, 32, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
