.container {
  max-width: 650px;
}

.page-content {
  margin-bottom: 56px;
} 

footer {
  position: fixed;
  bottom: 0;
}