footer.glass {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border: 0;
}

footer .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0 !important;
  color: white;
  text-align: center;
}

footer .list-group-item img {
  fill: #ccc;
}
